export default class Honeypot {

    constructor(debug = false) {

        const   honeypot    = jQuery('[data-id="sea_hp"]'),
                form        = jQuery(honeypot).closest('form'),
                submit      = jQuery(form).find('[type="submit"]'),
                container   = jQuery(form).parent();

        this.$honeypot    = jQuery(honeypot);
        this.$form        = jQuery(form);
        this.$submit      = jQuery(submit);
        this.$container   = jQuery(container);

        this.init();
        
        if(debug) {
            this.debug();
        }
    }

    init() {
        // add event listener
        // 'submit_success' also elementor trigger
        this.$form.on('submit', this.submitHandle.bind(this) );
        // on abort send
        this.$form.on('sea_form_abort_send', this.abortHandle.bind(this));
    }

    submitHandle(e) {
        this.validate(e);
    }

    validate(e) {

        let valid = (this.$honeypot.val() !== '' ) ? false : true;

        if (!valid) {
            this.abortSend(e);
        }
    }

    abortSend(e) {
        // e.preventDefault();  // form is still being send by Elementor

        // don't fake success as user should see if it's not working
        //this.$form.remove();
        //this.addMessage();

        // needed to stop Elementor to send form anyway
        e.stopImmediatePropagation();

        this.$form.trigger('sea_form_abort_send');
        
        return false;
    }

    abortHandle(e) {
        this.reset();
    }

    addMessage() {
        this.$container.append('<div class="elementor-message elementor-message-success" role="alert">Hey Bot! The form was sent successfully.</div>');
    }

    reset() {
        this.$honeypot.val('');
    }
    
    debug() {
        // add button to start form send via script
        this.$container.append('<button class="sea-form-bot" style="margin-top: 2rem;">Pretend Bot</button>').on('click', this.debugHandle.bind(this) );
    }

    /**
     * Light debugging
     * This is only to debug the things I needed so far..
     * - Fill in honeypot
     * - Fill out some textfields to check human interactio working
     * @param e
     */
    debugHandle(e) {

        const element = e.target,
              textinputs = this.$form.find('input:not([type="hidden"]):not([data-id="sea_hp"]), select, textarea');

        if( jQuery( element ).hasClass('sea-form-bot') ) {

            // test human_check not being triggered
            jQuery(textinputs).each((i, textinput) => {
                jQuery(textinput).val('debug textfield');
            });

            // test honeypot
            this.$honeypot.val('debug honeypot');

            // wait a little to be able to see what was filled in
            setTimeout(() => {
                this.$form.submit();
            }, 2000);
        }
    }
}