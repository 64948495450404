export default class Human {

    constructor(debug = false) {

        const human = jQuery('[data-id="sea_human_check"]');

        if( human.length > 0 ) {

            const   form    = jQuery(human).closest('form'),
                    submit  = jQuery(form).find('[type="submit"]'),
                    inputs  = jQuery(form).find('input:not([type="hidden"]):not([data-id="sea_hp"]), select, textarea');

            this.$human     = jQuery(human);
            this.$form      = jQuery(form);
            this.$inputs    = jQuery(inputs);
            this.$submit    = jQuery(submit);

            this.isHuman = false;
            this.md5 = '';

            this.init();

            // uncomment all debugs if no honeypot field otherwise use honeypot debug
            // if(debug) {
            //     const container = jQuery(form).parent();
            //     this.$container   = jQuery(container);
            //     this.debug();
            // }
        }
    }

    init() {

        // >= add event listener =======================================
        // 'submit_success' also elementor trigger
        this.$form.on('submit', this.submitHandle.bind(this) );
        // on success
        this.$form.on('reset', this.resetHandle.bind(this) ); // elementor hook

        // 1) delay listening to blur event
        setTimeout(this.setBlurEvent.bind(this), 2000);
        //setTimeout(()=>{console.log( 'Listening to user interacton now' );}, 2000);
        // <= add event listener =======================================
    }

    setBlurEvent() {

        // 2) on human interaction create random string and add it to field
        // detect human interaction
        this.$inputs.each((i, input) => {
            jQuery(input).on('blur', this.blurHandle.bind(this));
        });
    }

    /**
     * On human interaction
     * @param e
     */
    blurHandle(e) {
        if(!this.isHuman) {
            this.md5 = this.randomString();
            this.$human.val(this.md5);
            this.isHuman = true;
        }
    }
    
    addHidden() {
        this.$form.prepend(`<input id="sfo-md5" type="hidden" name="md5" value="${this.md5}">`);
    }

    removeHidden() {
        jQuery('input#sfo-md5').remove();
    }

    submitHandle(e) {
        this.validate(e);
    }

    validate(e) {

        let valid = true;
        
        // 3) check if human interaction was detected
        if(!this.isHuman) {
            valid = false;
        }

        if(valid) {
            // 4) check if random string is the right one
            valid = (this.$human.val() !== this.md5 ) ? false : true;
        }

        if (valid) {
            // 5) add hidden field to check md5 in php
            this.addHidden();
        }
    }
    
    resetHandle(e) {
        this.reset();
    }

    reset() {
        this.md5 = '';
        this.$human.val('');
        this.isHuman = false;
        this.removeHidden();
    }

    /**
     * Create a random string
     * @returns {string}
     */
    randomString() {
        return (Math.random() + 1).toString(36).substring(2);
    }

    // debug() {
    //     // add button to start form send via script
    //     this.$container.append('<button class="sea-form-bot" style="margin-top: 2rem;">Pretend Bot Human</button>').on('click', this.debugHandle.bind(this) );
    // }
    
    // debugHandle(e) {
    //
    //     const element = e.target;
    //
    //     if( jQuery( element ).hasClass('sea-form-bot') ) {
    //
    //         // test human not being triggered
    //         this.$inputs.each((i, textinput) => {
    //             jQuery(textinput).val('debug textfield');
    //         });
    //
    //         //this.$human.val('');
    //
    //         // wait a little to be able to see what was filled in
    //         setTimeout(() => {
    //             this.$form.submit();
    //         }, 2000);
    //     }
    // }
}