export default class Captcha {

    constructor() {
        const captcha   = jQuery('.sea-form-captcha'),
            refresh     = jQuery(captcha).find('.sfo-captcha__refresh'),
            image       = jQuery(captcha).find('.sfo-captcha__image'),
            form        = jQuery(captcha).closest('form')

        this.$captcha   = jQuery(captcha);
        this.$refresh   = jQuery(refresh);
        this.$image     = jQuery(image);
        this.$form      = jQuery(form);

        this.url_captcha = ( sfo_variables && sfo_variables.sfo_url ) ? sfo_variables.sfo_url + 'includes/captcha.php?' : false;
        
        this.init();
    }

    init() {
        this.$refresh.on('click', this.refreshHandle.bind(this) );
        
        this.$form.on('error', this.errorHandle.bind(this) ); // on fail
        this.$form.on('reset', this.resetHandle.bind(this) ); // on success

        if( this.$captcha.length > 1 ) {
            console.log('== Sea Form Captcha only optimized for one field per page. ==');
        }
    }
    
    errorHandle(e) {
        this.reset();  
    }
    resetHandle(e) {
        this.reset();
    }

    refreshHandle(e) {
        this.refresh();
    }
    
    reset() {
        this.refresh();
        this.$form.find('.elementor-field-type-sea_captcha .elementor-field-textual').val('');
    }

    refresh() {
        this.$image.attr('src', this.url_captcha + new Date().getTime() / 1000);
    }
}