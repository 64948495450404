//import Human_Hidden from "./modules/human-hidden";
import Captcha  from "./modules/captcha";
import Honeypot from "./modules/honeypot";
import Human    from "./modules/human";


'use strict';

( function ( $, w ) {

    var $window = $( w );

    $window.on( 'elementor/frontend/init', function() {

        // ---------------------------------------
        // add spambot protection
        const debugSeaForm = false;
        new Honeypot(debugSeaForm);
        // ---------------------------------------

        // ---------------------------------------
        // add additional protection
        //new Human_Hidden(debugSeaForm);
        new Human(debugSeaForm);
        // ---------------------------------------

        // ---------------------------------------
        // add captcha
        new Captcha();
        // ---------------------------------------
    });

} ( jQuery, window ) );